
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { ReactSession } from 'react-client-session';
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from '../context/languageContext'
import { useSearchContext } from '../context/searchContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRightToBracket,
    faSearch,
    faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import logo from '../assets/images/logoFlex.png';

const NavbarX = () => {
    const [showSearch, setShowSearch] = useState(1)
    const [urlParam, setUrlParam] = useState(1)
    const [scrollHeight, setScrollHeight] = useState(0);
                   
    const { language, setLanguage } = useLanguage();
    const { searchQuery, setSearchQuery } = useSearchContext();
    const { pathname } = useLocation();

    const handleScroll = () => {
        const currentScrollHeight = window.scrollY;
        setScrollHeight(currentScrollHeight);
    };

    function handleCloseSearch() {
        setShowSearch(1)
        setSearchQuery('')
    }

    function changeLanguage(lg) {
        setLanguage(lg)
        ReactSession.setStoreType("localStorage");
        ReactSession.set('flex-language', lg)
    }

    useEffect(() => {
        let url = pathname.split("/");
        setUrlParam('/' + url[1])
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname])

    return (
        <>
            <motion.nav className={`navbar position-fixed w-100 shadow ${scrollHeight > 60 ? ('background-main-color') : ('')}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div className="ps-4 navParent opacity-0">
                    <ul className={`navbar-language d-flex`}>
                        <li className="nav-item cursor-pointer">
                            <h6 className={`px-2 mb-0 me-2 text-click text-click-underline-opening ${language === 'pt' ? 'active' : ''}`} onClick={() => changeLanguage('pt')}>PT</h6>
                        </li>
                        <li className="nav-item cursor-pointer">
                            <h6 className={`px-2 mb-0 me-1 text-click text-click-underline-opening ${language === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')}>EN</h6>
                        </li>
                    </ul>
                </div>
                <div className="text-center m-auto navParent">
                    <img className="App-logo" src={logo} alt="Logomarca" onClick={() => window.location.replace('/projetos')}></img>
                </div>
                <div className="position-relative toolbar navParent">
                    <AnimatePresence mode="wait" initial={false}>
                        {showSearch === 1 && <motion.div key={'motion01'} className="ms-auto overflow-hidden" initial={{ width: '0px' }} animate={{ width: '100%' }} exit={{ width: '0px' }} transition={{ duration: 0.5,type: 'ease' }}>
                            <ul className={`navbar-pages search-bar d-flex`}>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/projetos' ? 'active' : ''}`} to={"/projetos"}>Projetos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/tecnologias' ? 'active' : ''}`} to={"/tecnologias"}>Tecnologias</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/sobre' ? 'active' : ''}`} to={"/sobre"}>Sobre</Link>
                                </li>
                                <li className="nav-item" onClick={() => setShowSearch(2)}>
                                    <FontAwesomeIcon icon={faSearch} size="lg" className="mx-2 iconsHoverNav" />
                                </li>
                            </ul>
                        </motion.div>}
                        {showSearch === 2 && <motion.div key={'motion02'} className="ms-auto overflow-hidden" initial={{ width: '0px' }} animate={{ width: '100%' }} exit={{ width: '0px' }} transition={{ duration: 0.5, type: 'ease' }}>
                            <div className={`toolbar__search d-flex align-items-center justify-content-end`}>
                                <FontAwesomeIcon icon={faCircleXmark} size="lg" className="toolbar__search__close iconsHoverNav" onClick={() => handleCloseSearch()} />
                                <input
                                    type="text"
                                    placeholder="Digite para pesquisar"
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </motion.div>}
                    </AnimatePresence>
                </div>
            </motion.nav>
        </>
    )
}

export default NavbarX